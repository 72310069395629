<template>
  <div
    class="
      f6
      h-100
      material-icons-loaded
      bg-concord-near-white
      concord-dark-gray
      fw4
      h-100
      mh0
      pa0
      roboto
      ember-application
      application
      pace-done
    "
    style="height: 100vh; overflow: hidden"
  >
    <div class="h-100 h-100 flex flex-column">
      <div class="flex h-100">
        <contract-setting-left-menu></contract-setting-left-menu>
        <div class="h-100 flex-auto text-align-left">
          <div class="flex flex-column h-100 w-100 pr4 bzbb">
            <MetaRightSideHeader>
              <template #title>
                所有团队
              </template>
              <template #middle-right>
                <el-button @click="addTeamClicked" type="primary">
                <span style="display: flex">
                  <!--                        <i class="iconfont icon-admin_new_add"></i>-->
                  <div class="new_add_container">
                    <span class="new_add"> + </span>
                  </div>
                  <span>新增团队</span>
                </span>
                </el-button>
              </template>
            </MetaRightSideHeader>
            <el-container
                class="flex flex-column flex-auto overflow-y-auto  bg-white shadow"
            >
              <!--                </div>-->
              <MetaListTable
                  :table-data="teamInfos"
                  :show-checkbox="true"
                  :table-height="'100%'"
              >
                <template #header>
                  <div class="action">
                    <div class="selector-wrap">
                      <MetaListSelector
                          :list="teamInfos"
                          :show-operation-action-btn="false"
                      ></MetaListSelector>
                    </div>
                  </div>
                </template>
                <template #default>

                  <el-table-column
                      prop="team_name"
                      label="团队名称"
                      min-width="150"
                      align="left"
                      sortable
                  >
                  </el-table-column>
                  <el-table-column
                      label="创建时间"
                      min-width="150"
                      align="center"
                      sortable
                      :formatter="timestampToTime"
                  >
                  </el-table-column>
                  <el-table-column
                      label="操作"
                      min-width="150"
                      align="center">
                    <template #default="scope">
                      <!-- <el-button @click="swtchItem(scope.$index)"
                        >切换</el-button
                      > -->
                      <div class="op_item" v-if="teamInfos[scope.$index].is_active && checkUserBehaviorPermission(userInfo.mainSiteTeamId,BehaviorPermission.MANAGE_TEAM_CONFIG)" @click="edit(scope.$index)">
                        编辑
                      </div>
                      <div
                          class="op_item"
                          v-else-if="teamInfos[scope.$index].is_active === false"
                          @click="switchUserCurrentTeam(scope.$index)"
                      >
                        切换
                      </div>
                    </template>
                  </el-table-column>
                </template>
              </MetaListTable>
              <!--                <el-table-->
              <!--                  :data="teamInfos"-->
              <!--                  style="width: 100%; border-top: #ecf5ff 1px solid"-->
              <!--                  max-height="550"-->
              <!--                >-->
              <!--                </el-table>-->
              <!--          <div v-if="system == 1" class="nextStep"><el-button type="primary" @click="nextStep">下一步</el-button></div>-->
            </el-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MetaMessage } from "../util";
import { ElLoading } from "element-plus";
import ContractSettingLeftMenu from "../components/ContractSettingLeftMenu";
import { getAllTeamInfos, switchCurrentTeam } from "../api/api";
import MetaListTable from "../components/list/MetaListTable";
import MetaListSelector from "../components/list/MetaListSelector";
import MetaRightSideHeader from "../components/universalUI/MetaRightSideHeader";
import {checkUserBehaviorPermission,BehaviorPermission} from "../components/common/permissionUtil";

export default {
  name: "teamManagement",
  data: function () {
    return {
      system: this.$route.query.system || 0,
      userInfo: JSON.parse(sessionStorage.getItem("userInfo")),
      teamInfo: JSON.parse(sessionStorage.getItem("teamInfo")),
      teamId: "",
      metaMessage: new MetaMessage(),
      activeOrganization: { id: "" },
      teamInfos: [],
      loadingInstance: null,
      inviteUrl: "",
      finalizedNeedNotCommitNewVersion: false,
      checkUserBehaviorPermission,
      BehaviorPermission
    };
  },
  mounted() {
    if (!this.userInfo) {
      this.$router.push({
        name: "contractHome",
      });
      return;
    }
    getAllTeamInfos().then((res) => {
      if (res.data.code == 0) {
        this.teamInfos = res.data.data;
      }
    });
    // this.teamId = this.userInfo.mainSiteTeamId;
    // this.finalizedNeedNotCommitNewVersion =
    //   this.teamInfo.finalizedNeedNotCommitNewVersion;
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    switchUserCurrentTeam(itemIndex) {
      switchCurrentTeam(this.teamInfos[itemIndex].team_id).then((res) => {
        console.log("切换到新团队,teamId：", this.teamInfos[itemIndex].team_id);
        if (res.data.code == 0) {
          this.metaMessage.success("切换成功");
          getAllTeamInfos().then((res) => {
            if (res.data.code == 0) {
              this.teamInfos = res.data.data;
            }
            location.reload();
          });
        } else {
          this.metaMessage.error("切换失败,请稍后再试");
          this.metaMessage.error(res.data.message);
        }
      });
    },
    edit(index) {
      this.$router.push({
        name: "editTeam",
        query: {
          teamId: this.teamInfos[index].team_id,
        },
      });
    },
    addTeamClicked() {
      this.$router.push({
        name: "addTeam",
      });
    },
    openLoading: function () {
      this.loadingInstance = ElLoading.service({
        fullscreen: true,
        body: true,
        lock: true,
        spinner: "el-icon-loading",
      });
    },
    closeLoading: function () {
      this.loadingInstance.close();
      this.loadingInstance = null;
    },
    timestampToTime:function(row) {
      let date = new Date(row.time_create * 1000 + 8 * 60 * 60 * 1000);
      return date.getFullYear() + "-" + (date.getMonth() < 10? '0'+(date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + (date.getDate() < 10 ? '0'+date.getDate() : date.getDate() )+ " " + (date.getHours() < 10 ?(date.getHours() == null ? '00' : '0'+date.getHours()):date.getHours()) + ":" + (date.getMinutes() < 10 ?(date.getMinutes() ==null ? '00' : '0'+date.getMinutes()):date.getMinutes()) + ":" + (date.getSeconds() < 10 ?(date.getSeconds() ==null ? '00' : '0'+date.getSeconds()):date.getSeconds());
    }
  },
  components: {
    MetaRightSideHeader,
    MetaListSelector,
    MetaListTable,
    ContractSettingLeftMenu,
  },
};
</script>

<style scoped>
.new_add_container {
  display: inline-block;
  padding-right: 6px;
  font-size: 20px;
  line-height: 13px;
}

.op_item {
  cursor: pointer;
  display: inline-block;
  padding: 0 10px;
  font-size: 14px;
  line-height: 14px;
  color: #409eff;
  user-select: none;
}

</style>
